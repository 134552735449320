import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Link as LinkScroll } from 'react-scroll'

import { HeaderContainerMixin } from './elements'

import logoImage from '../images/logo.svg'
import hamburgerImage from '../images/hamburger.svg'

const NavbarNav = styled.nav`
  ${HeaderContainerMixin}
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 200;
  @media (max-width: 991px) {
    background-color: #d1d1d1;
    border-bottom: 1px solid ${(props) => props.theme.colorGreen};
  }
`

const NavbarNavLogo = styled(Link)`
  display: inline-block;
  width: 24%;
  height: 85px;
  background-image: url(${logoImage});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  @media (min-width: 768px) and (max-width: 991px) {
    width: 180px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 160px;
    height: 75px;
  }
  @media (max-width: 575px) {
    width: 100px;
    height: 60px;
  }
`

const NavbarNavCollapse = styled.div`
  display: flex;
  flex-basis: auto;
  /* flex-grow: 1; */
  align-items: center;
  @media (max-width: 991px) {
    display: ${(props) => (props.open ? 'block' : 'none')};
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    background-color: #d1d1d1;
    padding-top: 20px;
    padding-left: 7.89%;
    padding-right: 5.26%;
    padding-bottom: 20px;
    border-bottom: 1px solid ${(props) => props.theme.colorGreen};
    transition: 0.2s ease;
    z-index: 200;
  }
`

const NavbarNavList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  @media (max-width: 991px) {
    display: block;
  }
`

const NavbarNavListItem = styled.li`
  display: inline-block;
  @media (max-width: 991px) {
    display: block;
  }
`

const NavbarNavListItemLink = styled(LinkScroll)`
  display: inline-block;
  font-size: 22px;
  font-family: ${(props) => props.theme.fontMetaPro};
  font-weight: 700;
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 15px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 991px) {
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 575px) {
    font-size: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
`

const NavbarButton = styled.div`
  position: absolute;
  top: 50%;
  right: 5.26%;
  width: 36px;
  height: 36px;
  background-image: url(${hamburgerImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: -18px;
  cursor: pointer;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 575px) {
    width: 30px;
    height: 30px;
    margin-top: -15px;
  }
`

const Navbar = () => {
  const [open, setOpen] = useState(false)

  const setToggleOpen = (open) => {
    setOpen(!open)
    console.log('Open: ', open)
  }

  return (
    <NavbarNav>
      <NavbarNavLogo to="/" />
      <NavbarButton onClick={() => setToggleOpen(open)} />
      <NavbarNavCollapse open={open}>
        <NavbarNavList>
          <NavbarNavListItem>
            <NavbarNavListItemLink
              href="#awards"
              to="awards"
              onClick={() => setToggleOpen(open)}
              smooth
            >
              nagrody
            </NavbarNavListItemLink>
          </NavbarNavListItem>
          <NavbarNavListItem>
            <NavbarNavListItemLink
              href="#rules"
              to="rules"
              onClick={() => setToggleOpen(open)}
              smooth
            >
              zasady
            </NavbarNavListItemLink>
          </NavbarNavListItem>
          <NavbarNavListItem>
            <NavbarNavListItemLink
              className="kuponDownloadLink"
              href="kupon.pdf"
              target="_blank"
              as="a"
              onClick={() => setToggleOpen(open)}
            >
              kupon zgłoszeniowy
            </NavbarNavListItemLink>
          </NavbarNavListItem>
          <NavbarNavListItem>
            <NavbarNavListItemLink
              href="regulamin-akcji.pdf"
              target="_blank"
              as="a"
              onClick={() => setToggleOpen(open)}
            >
              regulamin
            </NavbarNavListItemLink>
          </NavbarNavListItem>
          <NavbarNavListItem>
            <NavbarNavListItemLink
              href="#contacts"
              to="contacts"
              onClick={() => setToggleOpen(open)}
              smooth
            >
              kontakt
            </NavbarNavListItemLink>
          </NavbarNavListItem>
        </NavbarNavList>
      </NavbarNavCollapse>
    </NavbarNav>
  )
}

export default Navbar
