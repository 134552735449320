import React from 'react'

import Layout from '../components/layout'
import Header from '../components/header'
import Awards from '../components/awards'
import Prices from '../components/prices'
import Rules from '../components/rules'
import Account from '../components/account'
import Contacts from '../components/contacts'
import Footer from '../components/footer'

const IndexPage = () => (
  <Layout>
    <Header />
    <Awards />
    <Prices />
    <Rules />
    <Account />
    <Contacts />
    <Footer />
  </Layout>
)

export default IndexPage
