import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Textfit } from 'react-textfit'
import VisibilitySensor from 'react-visibility-sensor'

import {
  ContainerMixin,
  PaddingsContainerMixin,
  RowMixin,
  ColMixin,
  DescMixin,
  TitleMixin,
  PriceHeaderQty,
  CircleAnimate,
} from './elements'

import fagImage from '../images/fag-icon.svg'

const PricesWrapper = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 100;
  padding-top: 80px;
  padding-bottom: 15px;
  border-bottom: 15px solid ${(props) => props.theme.colorWhite};
  @media (max-width: 575px) {
    padding-top: 50px;
  }
`

const PricesContainer = styled.div`
  ${ContainerMixin}
`

const PricesRow = styled.div`
  ${RowMixin}
`

const PricesCol = styled.div`
  ${ColMixin}
`

const PricesBlockWrapper = styled.div``

const PricesSpecialWrapper = styled(PricesBlockWrapper)`
  padding-bottom: 80px;
  @media (max-width: 991px) {
    padding-bottom: 30px;
  }
`

const PricesLimitedWrapper = styled(PricesBlockWrapper)``

const PricesColHeaderTitle = styled(PricesCol)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 55%;
  max-width: 55%;
  ${(props) =>
    props.type === 'special' &&
    `
    padding-right: 0;
  `}
  ${(props) =>
    props.type === 'limited' &&
    `
    text-align: right;
  `}
  @media (max-width: 991px) {
    ${PaddingsContainerMixin}
    flex: 0 0 100%;
    max-width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
  }
`

const PricesHeaderTitleWrapper = styled.div``

const PricesHeaderTitle = styled.h2`
  ${TitleMixin}
  @media (max-width: 991px) {
    text-align: left !important;
  }
`

const PricesColHeaderComplects = styled(PricesCol)`
  ${(props) =>
    props.type === 'special' &&
    `
    text-align: right;
  `}
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    ${(props) =>
      props.type === 'limited' &&
      `
      order: 2
    `}
  }
`

const PricesHeaderComplectsQty = styled(PriceHeaderQty)`
  @media (min-width: 992px) {
    ${(props) =>
      props.type === 'limited' &&
      `
      left: -10px;
    `}
  }
`

const PricesRowDesc = styled(PricesRow)`
  padding-top: 30px;
  @media (min-width: 992px) and (max-width: 1199px) {
    /* padding-top: 10px; */
  }
`

const PricesColImages = styled(PricesCol)`
  overflow: hidden;
  flex: 0 0 55%;
  max-width: 55%;
  padding-right: 0;
  border-top: 50px solid ${(props) => props.theme.colorGreen};
  ${(props) =>
    props.type === 'special'
      ? `
    padding-right: 0;
  `
      : `

  `}
  @media (max-width: 1199px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    border-top: 30px solid ${(props) => props.theme.colorGreen};
  }
  @media (max-width: 575px) {
    border-top: 15px solid ${(props) => props.theme.colorGreen};
  }
`

const PricesColDesc = styled(PricesCol)`
  ${DescMixin}
  flex: 0 0 45%;
  max-width: 45%;
  text-align: ${(props) => props.type === 'special' && `right`};
  padding-top: 25px;
  @media (max-width: 1199px) {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left;
  }
  @media (min-width: 576px) {
    ${(props) =>
      props.type === 'special'
        ? `
      padding-left: 50px;
      padding-right: 7.885%;
    `
        : `
      padding-left: 7.885%;
      padding-right: 50px;
    `}
  }
  @media (max-width: 575px) {
    ${PaddingsContainerMixin}
  }
  p {
    &:last-of-type {
      ${(props) =>
        props.type === 'limited' &&
        `
        margin-bottom: 0;
      `}
    }
  }
`

const PricesImagesWrapper = styled.div`
  display: Flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #fff;
  .gatsby-image-wrapper {
    width: 100%;
  }
`

const PricesColImagesBig = styled(PricesCol)`
  padding-left: 0;
  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  &:nth-of-type(2) {
    @media (min-width: 576px) {
      padding-left: 15px;
    }
    @media (max-width: 575px) {
      margin-top: 15px;
      margin-bottom: 15px;
      padding-right: 15px;
    }
  }
  ${PricesImagesWrapper} {
    padding: 50px;
    @media (max-width: 575px) {
      padding: 30px;
    }
  }
`

const PricesColImagesBigSubDesc = styled(PricesCol)`
  font-family: ${(props) => props.theme.fontMetaPro};
  font-size: 20px;
  padding-top: 35px;
  padding-bottom: 10px;
  @media (max-width: 575px) {
    ${PaddingsContainerMixin}
  }
`

const PricesRowImagesOther = styled(PricesRow)`
  height: 100%;
`

const PricesColImagesOther = styled(PricesCol)`
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 15px;
    padding-left: 0;
    padding-bottom: 10px;
  }
`

const PricesColImagesOtherInner = styled(PricesCol)`
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 30px;
  @media (max-width: 575px) {
    padding-left: 0;
    margin-bottom: 15px;
  }
  &:nth-last-of-type(1),
  &:nth-last-of-type(2) {
    @media (min-width: 576px) {
      margin-bottom: 0;
    }
  }
`

const PricesGeneralWrapper = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  @media (max-width: 575px) {
    padding-top: 40px;
    padding-bottom: 20px;
  }
`

const PricesGeneralImage = styled(PricesCol)`
  flex: 0 0 35%;
  max-width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 992px) and (max-width: 1199px) {
    flex: 0 0 30%;
    max-width: 30%;
  }
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const PricesGeneralDesc = styled(PricesCol)`
  flex: 0 0 65%;
  max-width: 65%;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (min-width: 992px) and (max-width: 1199px) {
    flex: 0 0 70%;
    max-width: 70%;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media (max-width: 575px) {
    ${PaddingsContainerMixin}
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 20px;
  }
  p {
    font-family: ${(props) => props.theme.fontMetaPro};
    font-size: 100px;
    font-weight: 700;
    text-transform: uppercase;
    @media (min-width: 1200px) and (max-width: 1599px) {
      font-size: 80px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 56px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 40px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 30px;
    }
    @media (max-width: 575px) {
      font-size: 26px;
    }
  }
`

const PricesIconLogo = styled.div`
  width: 100px;
  height: 40px;
  background-image: url(${fagImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: 25px;
  @media (min-width: 1200px) {
    ${(props) =>
      props.align === 'right' &&
      `
      margin-left: auto;
      margin-right: 0;
    `}
  }
  @media (max-width: 1199px) {
    ${(props) =>
      props.align === 'left' &&
      `
      margin-bottom: 30px;
    `}
  }
  @media (max-width: 575px) {
    width: 70px;
    /* ${(props) =>
      props.align === 'left' &&
      `
      margin-top: 0;
    `} */
    ${(props) =>
      props.align === 'right' &&
      `
      margin-left: 5%;
    `}
  }
`

const CircleAnimateWrapper = styled(CircleAnimate)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const Prices = () => {
  const data = useStaticQuery(graphql`
    query {
      pricesSpecialImage1: file(relativePath: { eq: "prices/softshell.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      pricesSpecialImage2: file(relativePath: { eq: "prices/mysz.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      pricesSpecialImage3: file(
        relativePath: { eq: "prices/podkladka-pod-mysz.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      pricesSpecialImage4: file(relativePath: { eq: "prices/notes.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      pricesSpecialImage5: file(
        relativePath: { eq: "prices/plecak-worek.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      pricesLimitedImage1: file(
        relativePath: { eq: "prices/bluza-schaeffler.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      pricesLimitedImage2: file(
        relativePath: { eq: "prices/głośnik-FAG-szary.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const mediaSizesPriceCircle = [
    {
      '1600': {
        circleSize: 400,
        insetSize: 330,
      },
    },
    {
      '1200': {
        circleSize: 350,
        insetSize: 290,
      },
    },
    {
      '992': {
        circleSize: 270,
        insetSize: 220,
      },
    },
    {
      '768': {
        circleSize: 230,
        insetSize: 190,
      },
    },
    {
      '576': {
        circleSize: 190,
        insetSize: 150,
      },
    },
    {
      '0': {
        circleSize: 190,
        insetSize: 150,
      },
    },
  ]

  const [percentCircleAnimate, setPercentCircleAnimate] = useState(0)

  const setPercentCircleAnimateAction = (isVisible) => {
    if (isVisible) {
      setPercentCircleAnimate(100)
    }
  }

  return (
    <PricesWrapper id="prices">
      <PricesContainer>
        <PricesSpecialWrapper>
          <PricesRow>
            <PricesColHeaderTitle type="special">
              <PricesHeaderTitleWrapper>
                <PricesHeaderTitle>
                  <span className="firstText">Nagrody specjalne</span>{' '}
                  <span className="lastText">
                    za zakupy produktów marki FAG
                  </span>
                </PricesHeaderTitle>
              </PricesHeaderTitleWrapper>
            </PricesColHeaderTitle>
            <PricesColHeaderComplects type="special">
              <PricesHeaderComplectsQty>
                <div className="qtyWrapper">
                  <span>160</span> <span className="qtyText">zestawów</span>
                </div>
              </PricesHeaderComplectsQty>
            </PricesColHeaderComplects>
          </PricesRow>
          <PricesRowDesc>
            <PricesColImages type="special">
              <PricesRow>
                <PricesColImagesBig>
                  <PricesImagesWrapper>
                    <Img
                      fluid={data.pricesSpecialImage1.childImageSharp.fluid}
                    />
                  </PricesImagesWrapper>
                </PricesColImagesBig>
                <PricesColImagesOther>
                  <PricesRowImagesOther>
                    <PricesColImagesOtherInner>
                      <PricesImagesWrapper>
                        <Img
                          fluid={data.pricesSpecialImage2.childImageSharp.fluid}
                        />
                      </PricesImagesWrapper>
                    </PricesColImagesOtherInner>
                    <PricesColImagesOtherInner>
                      <PricesImagesWrapper>
                        <Img
                          fluid={data.pricesSpecialImage3.childImageSharp.fluid}
                        />
                      </PricesImagesWrapper>
                    </PricesColImagesOtherInner>
                    <PricesColImagesOtherInner>
                      <PricesImagesWrapper>
                        <Img
                          fluid={data.pricesSpecialImage4.childImageSharp.fluid}
                        />
                      </PricesImagesWrapper>
                    </PricesColImagesOtherInner>
                    <PricesColImagesOtherInner>
                      <PricesImagesWrapper>
                        <Img
                          fluid={data.pricesSpecialImage5.childImageSharp.fluid}
                        />
                      </PricesImagesWrapper>
                    </PricesColImagesOtherInner>
                  </PricesRowImagesOther>
                </PricesColImagesOther>
              </PricesRow>
              <div>
                <PricesIconLogo align="right" />
              </div>
            </PricesColImages>
            <PricesColDesc type="special">
              <p>
                <strong>
                  Pakiet premium dla najlepszych w{'\u00A0'}tygodniu
                </strong>
                <br /> Wyprzedź innych, kupując produkty FAG i{'\u00A0'}wygrywaj
                zestawy premium dla Ciebie lub Twojego warsztatu!
              </p>
              <p>
                Dla tych, którzy kupią najwięcej produktów FAG w{'\u00A0'}jednym
                tygodniu mamy prawdziwy rarytas - brandowany zestaw:{' '}
                <strong className="green">
                  Softshell, plecak, podkładka pod mysz oraz notes z{'\u00A0'}
                  unikalnego papieru z{'\u00A0'}kamienia
                </strong>{' '}
                - wszystko to{'\u00A0'}czeka na{'\u00A0'}pierwszych na{'\u00A0'}
                mecie!
              </p>
            </PricesColDesc>
          </PricesRowDesc>
        </PricesSpecialWrapper>

        <PricesLimitedWrapper>
          <PricesRow>
            <PricesColHeaderComplects type="limited">
              <PricesHeaderComplectsQty type="limited">
                <div className="qtyWrapper">
                  <span>500</span> <span className="qtyText">zestawów</span>
                </div>
              </PricesHeaderComplectsQty>
            </PricesColHeaderComplects>
            <PricesColHeaderTitle type="limited">
              <PricesHeaderTitleWrapper>
                <PricesHeaderTitle>
                  <span className="firstText">Limitowana edycja</span>{' '}
                  <span className="lastText">
                    Limitowana edycja bluzy FAG wraz
                    <br /> z{'\u00A0'}głośnikiem Bluetooth
                  </span>
                </PricesHeaderTitle>
              </PricesHeaderTitleWrapper>
            </PricesColHeaderTitle>
          </PricesRow>
          <PricesRowDesc>
            <PricesColDesc type="limited">
              <p>
                Na pierwszych{' '}
                <strong className="green">500* uczestników</strong>{' '}
                <strong>akcji,</strong> którzy wyślą <strong>10 kodów</strong>{' '}
                paskowych wyciętych z opakowania dowolnego produktu FAG
                zawieszenia, czeka wyjątkowy zestaw:{' '}
                <strong>bluza z limitowanej edycji</strong> oraz{' '}
                <strong>głośnik Bluetooth.</strong>
              </p>
              <div>
                <PricesIconLogo align="left" />
              </div>
            </PricesColDesc>
            <PricesColImages type="limited">
              <PricesRow>
                <PricesColImagesBig type="limited">
                  <PricesImagesWrapper>
                    <Img
                      fluid={data.pricesLimitedImage1.childImageSharp.fluid}
                    />
                  </PricesImagesWrapper>
                </PricesColImagesBig>
                <PricesColImagesBig type="limited">
                  <PricesImagesWrapper style={{ paddingRight: 0 }}>
                    <Img
                      fluid={data.pricesLimitedImage2.childImageSharp.fluid}
                    />
                  </PricesImagesWrapper>
                </PricesColImagesBig>
                <PricesColImagesBigSubDesc>
                  * Decyduje data na stemplu pocztowym. Kody można wysyłać do
                  20.06.2020
                </PricesColImagesBigSubDesc>
              </PricesRow>
            </PricesColImages>
          </PricesRowDesc>
        </PricesLimitedWrapper>
        <PricesGeneralWrapper>
          <PricesRow>
            <PricesGeneralImage>
              <VisibilitySensor onChange={setPercentCircleAnimateAction}>
                <CircleAnimateWrapper
                  mediaSizes={mediaSizesPriceCircle}
                  circleBackground="#cfcfcf"
                  circleColor="#008132"
                  insetColor="#e8e8e8"
                  transitionLength={1.5}
                  percentCircleAnimate={percentCircleAnimate}
                />
              </VisibilitySensor>
            </PricesGeneralImage>
            <PricesGeneralDesc>
              <p>
                <Textfit mode="multi" forceSingleModeWidth={true}>
                  Łączna wartość
                  <br />
                  nagród to ponad
                  <br />
                  250.000 PLN!
                </Textfit>
              </p>
            </PricesGeneralDesc>
          </PricesRow>
        </PricesGeneralWrapper>
      </PricesContainer>
    </PricesWrapper>
  )
}

export default Prices
