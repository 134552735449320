import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import handImage from '../images/hand.svg'

import {
  ContainerMixin,
  RowMixin,
  ColMixin,
  DescMixin,
  PaddingsContainerMixin,
  Button,
} from './elements'

import logoIcon from '../images/logo.svg'

const AccountWrapper = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  background-size: 40%;
  padding-top: 150px;
  padding-bottom: 30px;
  z-index: 80;
  @media (min-width: 768px) {
    background-image: url(${handImage});
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 100px;
  }
  @media (max-width: 575px) {
    padding-top: 80px;
  }
`

const AccountContainer = styled.div`
  ${ContainerMixin}
`

const AccountRow = styled.div`
  ${RowMixin}
`

const AccountCol = styled.div`
  ${ColMixin}
`

const AccountColLogo = styled(AccountCol)`
  flex: 0 0 100%;
  max-width: 100%;
  ${PaddingsContainerMixin}
  padding-right: 15px;
  padding-bottom: 80px;
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 60px;
  }
  @media (max-width: 575px) {
    padding-bottom: 45px;
  }
`

const AccountLogoWrapper = styled.div`
  display: block;
  background-image: url(${logoIcon});
  background-repeat: no-repeat;
  background-position: center left;
  background-size: contain;
  height: 65px;
`

const AccountColDesc = styled(AccountCol)`
  ${PaddingsContainerMixin}
  ${DescMixin}
  @media (min-width: 768px) {
    flex: 0 0 60%;
    max-width: 60%;
    padding-right: 0;
  }
`

const AccountButtonLink = styled(Button)`
  width: 290px;
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 260px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 220px;
  }
  @media (max-width: 575px) {
    display: block;
    width: 210px;
    margin-left: auto;
    margin-right: auto;
  }
`

const AccountColLogos = styled(AccountCol)`
  ${PaddingsContainerMixin}
  text-align: right;
  padding-top: 40px;
`

const AccountLogosWrapper = styled.div`
  display: inline-block;
  width: 220px;
  @media (max-width: 575px) {
    display: block;
    width: 180px;
    margin-left: auto;
    margin-right: auto;
  }
`

const Account = () => {
  const data = useStaticQuery(graphql`
    query {
      accountLogoImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      accountLogosImage: file(relativePath: { eq: "logos.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <AccountWrapper id="account">
      <AccountContainer>
        <AccountRow>
          <AccountColLogo>
            <AccountLogoWrapper />
          </AccountColLogo>
        </AccountRow>
        <AccountRow>
          <AccountColDesc>
            <p>
              Nie masz konta REPXPERT?
              <br />
              Załóż je koniecznie! Wejdź na{' '}
              <strong>
                na{' '}
                <a
                  href="https://www.repxpert.pl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.repxpert.pl
                </a>
              </strong>{' '}
              i ułatw sobie życie - dobór części, informacje o produktach do
              silnika, zawieszenia czy przeniesienia napędu, szczegółowe
              wskazówki montażu i telefoniczne wsparcie dla mechaników oraz
              nielimitowany dostęp do pomocy naszych ekspertów technicznych{' '}
              <strong>- zupełnie za darmo!</strong>
            </p>
          </AccountColDesc>
        </AccountRow>
        <AccountRow>
          <AccountColDesc>
            <AccountButtonLink
              href="https://www.repxpert.pl/pl/register"
              color="#fff"
              bgColor="#00863d"
              target="_blank"
              rel="noopener noreferrer"
            >
              załóż konto
            </AccountButtonLink>
          </AccountColDesc>
        </AccountRow>
        <AccountRow>
          <AccountColLogos>
            <AccountLogosWrapper>
              <Img fluid={data.accountLogosImage.childImageSharp.fluid} />
            </AccountLogosWrapper>
          </AccountColLogos>
        </AccountRow>
      </AccountContainer>
    </AccountWrapper>
  )
}

export default Account
