import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import {
  ContainerMixin,
  RowMixin,
  ColMixin,
  DescMixin,
  TitleMixin,
  PaddingsContainerMixin,
} from './elements'

import logoIcon from '../images/logo.svg'

const RulesWrapper = styled.div`
  position: relative;
  background-color: #cfcfcf;
  z-index: 100;
  padding-top: 80px;
  padding-bottom: 15px;
  @media (max-width: 575px) {
    padding-top: 50px;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 75%;
    height: 50px;
    background-color: ${(props) => props.theme.colorGreen};
    margin-bottom: -25px;
    z-index: 100;
    @media (min-width: 992px) and (max-width: 1199px) {
      height: 40px;
      margin-bottom: -20px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      height: 30px;
      margin-bottom: -15px;
    }
    @media (max-width: 575px) {
      height: 20px;
      margin-bottom: -10px;
    }
  }
`

const RulesContainer = styled.div`
  ${ContainerMixin}
`

const RulesRow = styled.div`
  ${RowMixin}
`

const RulesCol = styled.div`
  ${ColMixin}
`

const RulesColTitle = styled(RulesCol)`
  ${PaddingsContainerMixin}
`

const RulesTitle = styled.h2`
  ${TitleMixin}
  padding-bottom: 15px;
`

const RulesRowDesc = styled(RulesRow)`
  ${PaddingsContainerMixin}
  margin-left: -30px;
`

const RulesColDesc = styled(RulesCol)`
  ${DescMixin}
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  @media (min-width: 992px) {
    padding-right: 80px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-right: 45px;
  }
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  a {
    text-decoration: underline;
  }
`

const RulesDescLogoWrapper = styled.div`
  min-height: 85px;
  background-image: url(${logoIcon});
  background-repeat: no-repeat;
  background-position: center left;
  background-size: contain;
  margin-right: 80px;
  margin-bottom: 5px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    min-height: 80px;
  }
  @media (max-width: 991px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    height: 60px;
    margin-right: 0;
  }
  @media (max-width: 575px) {
    height: 40px;
    margin-right: 0;
  }
`

const RulesRowDescMiddle = styled(RulesRow)`
  ${PaddingsContainerMixin}
  overflow: hidden;
  margin-left: -30px;
  @media (min-width: 1200px) {
    padding-right: 4.885%;
  }
`

const RulesColDescMiddle = styled(RulesCol)`
  ${DescMixin}
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  position: relative;
  padding-top: 120px;
  padding-right: 85px;
  @media (min-width: 1200px) {
    flex: 0 0 63.666667%;
    max-width: 63.666667%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 90px;
    padding-right: 65px;
  }
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 90px;
    padding-right: 65px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 60px;
    padding-right: 65px;
  }
  @media (max-width: 575px) {
    padding-top: 50px;
    padding-right: 65px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 50px;
    display: block;
    width: 2000px;
    height: 50px;
    background-color: ${(props) => props.theme.colorGreen};
    @media (min-width: 992px) and (max-width: 1199px) {
      height: 40px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      height: 40px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      height: 30px;
    }
    @media (max-width: 575px) {
      height: 20px;
    }
  }

  a {
    text-decoration: underline;
  }
`

const RulesColImageMiddle = styled(RulesCol)`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-top: 55px;
  @media (min-width: 1200px) {
    flex: 0 0 36.333333%;
    max-width: 36.333333%;
    padding-top: 80px;
  }
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 30px;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 30px;
  }
  @media (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const RulesColDescBottom = styled(RulesCol)`
  ${DescMixin}
  ${PaddingsContainerMixin}
  flex: 0 0 100%;
  max-width: 100%;
`

const RulesRowDescBottomMiddle = styled(RulesRow)`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 65px;
    background-color: ${(props) => props.theme.colorGreen};
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 50px;
    }
    @media (max-width: 991px) {
      content: none;
    }
  }
`

const RulesColDescTitleBottomMiddle = styled(RulesCol)`
  ${DescMixin}
  ${PaddingsContainerMixin}
  flex: 0 0 100%;
  max-width: 100%;
`

const RulesColDescBottomMiddle = styled(RulesCol)`
  ${DescMixin}
  ${PaddingsContainerMixin}



  @media (min-width: 992px) {
    padding-left: 0;
    margin-left: 25%;
    padding-right: 200px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-right: 150px;
    margin-left: 20%;
  }
  @media (max-width: 991px) {
  }
`

const RulesColDescBottomBottom = styled(RulesColDescBottom)`
  padding-top: 120px;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 90px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 80px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 70px;
  }
  @media (max-width: 575px) {
    padding-top: 60px;
  }
`

const Rules = () => {
  const data = useStaticQuery(graphql`
    query {
      rulesFAGImage: file(relativePath: { eq: "rules.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <RulesWrapper id="rules">
      <RulesContainer>
        <RulesRow>
          <RulesColTitle>
            <RulesTitle>
              <span className="firstText">ZASADY</span>{' '}
              <span className="lastText">
                WYGRANA JEST PROSTSZA NIŻ MYŚLISZ!
              </span>
            </RulesTitle>
          </RulesColTitle>
        </RulesRow>
        <RulesRowDesc>
          <RulesColDesc>
            <p className="numberRule">1</p>
            <p className="withNumberRule">
              Kup <strong>dowolny produkt marek LuK, INA, FAG</strong> u
              {'\u00A0'}jednego z{'\u00A0'}Autoryzowanych Dystrybutorów lub
              Partnera Schaeffler Polska Sp. z{'\u00A0'}o.o.
            </p>
          </RulesColDesc>
          <RulesColDesc>
            <p className="numberRule">2</p>
            <p className="withNumberRule">
              Zarejestruj swój zakup w{'\u00A0'}portalu dla mechaników{' '}
              <a
                href="https://www.repxpert.pl"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.repxpert.pl
              </a>
            </p>
          </RulesColDesc>
          <RulesColDesc>
            <RulesDescLogoWrapper />
            <p>
              Jeśli kupiony przez Ciebie produkt zawiera kupon bonusowy, to
              {'\u00A0'}
              zaloguj się na{'\u00A0'}swoje konto w portalu{' '}
              <strong>REPXPERT.PL</strong> i{'\u00A0'}zarejestruj znajdujący się
              na nim kod. Nie masz konta? Załóż je{'\u00A0'}na{'\u00A0'}portalu{' '}
              <a
                href="https://www.repxpert.pl"
                target="_blank"
                rel="noopener noreferrer"
              >
                repxpert.pl
              </a>
              .
            </p>
          </RulesColDesc>
        </RulesRowDesc>
        <RulesRowDescMiddle>
          <RulesColDescMiddle>
            <p>
              Jeżeli kupiłeś <strong>elementy zawieszenia FAG,</strong> to
              wytnij z{'\u00A0'}tekturowego opakowania fragment zawierający
              nazwę produktu <strong>wraz z kodem kreskowym.</strong> Następnie
              wypełnij{' '}
              <a className="kuponDownloadLink" href="kupon.pdf" target="_blank">
                kupon zgłoszeniowy
              </a>{' '}
              i wyślij wraz z wyciętymi kodami na adres:
              <br />{' '}
              <strong className="green">
                Digital Wonderland Sp. z o.o., ul. Kijowska 5, 03-738 Warszawa.
              </strong>
              <br /> Na kopercie umieść przypis{' '}
              <strong>„Kierowca na medal!”</strong>
            </p>
          </RulesColDescMiddle>
          <RulesColImageMiddle>
            <Img fluid={data.rulesFAGImage.childImageSharp.fluid} />
          </RulesColImageMiddle>
        </RulesRowDescMiddle>
        <RulesRow>
          <RulesColDescBottom>
            <p className="numberRule">3</p>
          </RulesColDescBottom>
        </RulesRow>
        <RulesRowDescBottomMiddle>
          <RulesColDescTitleBottomMiddle>
            <p className="titleDesc withNumberRule">
              Weryfikacja
              <br /> i wydanie nagród
            </p>
          </RulesColDescTitleBottomMiddle>
          <RulesColDescBottomMiddle>
            <p className="withNumberRule">
              Nagrody główne i nagrody dodatkowe.
              <br /> Weryfikacja punktacji odbędzie się w terminie do{' '}
              <strong className="green">1 lipca 2020 r.</strong> Terminy
              realizacji nagrody głównej ustalone zostaną indywidualnie z
              {'\u00A0'}
              nagrodzonymi uczestnikami.
            </p>
            <p className="withNumberRule">
              Nagrody w kategorii „Najlepszy w tygodniu”.
              <br /> Weryfikacja punktacji odbywać się będzie w ciągu następnego
              tygodnia po{'\u00A0'}zakończeniu poszczególnych edycji.
            </p>
          </RulesColDescBottomMiddle>

          <RulesColDescTitleBottomMiddle>
            <p className="titleDesc withNumberRule">
              Terminy realizacji
              <br /> nagród
            </p>
          </RulesColDescTitleBottomMiddle>
          <RulesColDescBottomMiddle>
            <p className="no-mb withNumberRule">
              Nagrody gwarantowane za przesłanie 10 kodów kreskowych z opakowań.
              Weryfikacja punktacji odbywać się będzie w cyklach tygodniowych
              rozpoczynając od następnego tygodnia od daty otrzymania przesyłki.
              Nagrody wysyłane będą pocztą w ciągu 7 dni od zatwierdzenia
              punktacji.
            </p>
          </RulesColDescBottomMiddle>
        </RulesRowDescBottomMiddle>

        <RulesRow>
          <RulesColDescBottomBottom>
            <p>
              Akcja skierowana jest do przedsiębiorców, którzy dokonują zakupów
              produktów marek:
              <br />
              LuK, INA, FAG u Autoryzowanych Dystrybutorów lub Partnerów
              Schaeffler Sp. z o.o. Akcja trwa od 27.04.2020 r. do 22.06.2020 r.
              <br />
              Szczegółowy opis zasad promocji znajduje się w Regulaminie oraz na
              stronie www.repxpert.pl Operatorem programu działającym na
              zlecenie Organizatora - Schaeffler Sp. z o.o., jest Digital
              Wonderland Sp. z{'\u00A0'}o.o. z{'\u00A0'}siedzibą w Warszawie
              przy ul. Postępu 15C, 02-676 Warszawa.
            </p>
          </RulesColDescBottomBottom>
        </RulesRow>
      </RulesContainer>
    </RulesWrapper>
  )
}

export default Rules
