import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Textfit } from 'react-textfit'

import { RowMixin, ColMixin, HeaderContainerMixin } from './elements'

const IntroWrapper = styled.div`
  ${HeaderContainerMixin}
  overflow: hidden;
  position: relative;
  z-index: 100;
  padding-top: 9.5%;
`

const IntroRow = styled.div`
  ${RowMixin}
`

const IntroCol = styled.div`
  ${ColMixin}
`

const IntroColTitle = styled(IntroCol)`
  flex: 0 0 37%;
  max-width: 37%;
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const IntroTitle = styled.h1`
  font-family: ${(props) => props.theme.fontMetaPro};
  font-size: 120px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 80px;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 50px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 40px;
  }
  @media (max-width: 575px) {
    font-size: 30px;
  }
`

const IntroColDesc = styled(IntroCol)`
  display: flex;
  align-items: flex-end;
  font-size: 20px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
  }
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 18px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
`

const IntroDescWrapper = styled.div`
  position: relative;
  @media (min-width: 1200px) {
    padding-left: 70%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 60%;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -100px;
    bottom: 0;
    display: block;
    width: 65px;
    background-color: ${(props) => props.theme.colorGreen};
    @media (min-width: 992px) and (max-width: 1199px) {
      right: -80px;
    }
    @media (max-width: 991px) {
      content: none;
    }
  }
  p {
    margin-bottom: 25px;
  }
`

const IntroImgWrapper = styled.div`
  padding-right: 40px;
  margin-bottom: 40px;
  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 240px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 200px;
  }
  @media (max-width: 575px) {
    width: 160px;
  }
`

const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logos.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <IntroWrapper>
      <IntroRow>
        <IntroColTitle>
          <IntroTitle>
            <Textfit mode="multi" forceSingleModeWidth={true}>
              Sprawdź
              <br /> czy
              <br /> jesteś
              <br /> Kierowcą
              <br /> na medal?
            </Textfit>
          </IntroTitle>
        </IntroColTitle>
        <IntroColDesc>
          <IntroDescWrapper>
            <p>
              Kupuj nasze marki, odbieraj gwarantowane nagrody z{'\u00A0'}
              limitowanej edycji, sprawdź się na{'\u00A0'}torze z{'\u00A0'}
              Tomaszem Kucharem i{'\u00A0'}wiele więcej!
            </p>
            <p>Łączna wartość nagród to{'\u00A0'}ponad 250 000 zł!</p>
            <IntroImgWrapper>
              <Img fluid={data.placeholderImage.childImageSharp.fluid} />
            </IntroImgWrapper>
          </IntroDescWrapper>
        </IntroColDesc>
      </IntroRow>
    </IntroWrapper>
  )
}

export default Intro
