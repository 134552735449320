import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import VisibilitySensor from 'react-visibility-sensor'

import {
  ContainerMixin,
  PaddingsContainerMixin,
  RowMixin,
  ColMixin,
  DescMixin,
  PriceHeaderQty,
  CircleAnimate,
} from './elements'

const AwardsWrapper = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 100;
  padding-top: 150px;
  padding-bottom: 15px;
  border-bottom: 15px solid ${(props) => props.theme.colorWhite};
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 80px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 60px;
  }
  @media (max-width: 575px) {
    padding-top: 50px;
  }
`

const AwardsContainer = styled.div`
  ${ContainerMixin}
`

const AwardsRow = styled.div`
  ${RowMixin}
`

const AwardsCol = styled.div`
  ${ColMixin}
`

const AwardsColHeader = styled(AwardsCol)`
  padding-left: 0;
  padding-bottom: 50px;
  margin-left: 60%;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-bottom: 40px;
  }
  @media (max-width: 991px) {
    ${PaddingsContainerMixin}
    margin-left: 0;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 30px;
  }
  @media (max-width: 575px) {
    padding-bottom: 25px;
  }
`

const AwardsHeaderTitle = styled.h2`
  font-family: ${(props) => props.theme.fontMetaPro};
  font-weight: 700;
  font-size: 80px;
  text-transform: uppercase;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 60px;
  }
  @media (max-width: 991px) {
    text-align: center;
    padding-left: 15px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 50px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 42px;
  }
  @media (max-width: 575px) {
    font-size: 38px;
  }
`

const AwardsColDesc = styled(AwardsCol)`
  flex: 0 0 60%;
  max-width: 60%;
  padding-left: 10.51%;
  padding-right: 5.257%;
  ${DescMixin}
  @media (max-width: 991px) {
    ${PaddingsContainerMixin}
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const AwardsDescWrapper = styled.div`
  position: relative;
  h3,
  p {
    position: relative;
    z-index: 10;
  }
  h3 {
    @media (min-width: 1600px) {
      padding-top: 12px;
      margin-bottom: 65px;
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      padding-top: 10px;
      margin-bottom: 50px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      padding-top: 8px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding-top: 5px;
      margin-bottom: 35px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      padding-top: 5px;
      margin-bottom: 30px;
    }
    @media (max-width: 575px) {
      padding-top: 5px;
      margin-bottom: 20px;
    }
  }
  p {
    &:last-of-type {
      /* margin-bottom: 0; */
    }
  }
`

const AwardsDescInnerWrapper = styled.div`
  position: relative;
  background-color: #e8e8e8;
  padding-left: 15px;
  z-index: 10;
  @media (min-width: 1600px) {
    padding-left: 20px;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
`

const AwardsCircleAnimate = styled(CircleAnimate)`
  position: absolute;
  top: -120px;
  left: -95px;
  transform: rotate(90deg);
  @media (min-width: 992px) and (max-width: 1199px) {
    top: -105px;
    left: -85px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    top: -76px;
    left: -59px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    top: -64px;
    left: -49px;
  }
  @media (max-width: 575px) {
    top: -38px;
    left: -22px;
  }
`

const AwardsColDescImage = styled(AwardsCol)`
  padding-left: 0;
  padding-right: 0;
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 50px;
  }
  .barAnimate {
    position: relative;
    padding-top: 55px;
    @media (min-width: 576px) and (max-width: 767px) {
      padding-top: 50px;
    }
    @media (max-width: 575px) {
      padding-top: 20px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: ${(props) => (props.animate ? '0' : '100%')};
      right: 0;
      bottom: 0;
      display: block;
      background-color: ${(props) => props.theme.colorGreen};
      transition: left 1s;
    }
  }
`

const AwardsColDescMore = styled(AwardsCol)`
  display: flex;
  align-items: flex-end;
  ${DescMixin}
  @media (min-width: 576px) {
    text-align: right;
  }
  @media (min-width: 992px) {
    flex: 0 0 45%;
    max-width: 45%;
  }
  @media (max-width: 575px) {
    ${PaddingsContainerMixin}
  }
  h3 {
    color: ${(props) => props.theme.colorGreen};
  }
  p {
    margin-bottom: 15px;
  }
`

const AwardsDescMoreWrapper = styled.div`
  @media (max-width: 991px) {
    padding-top: 30px;
  }
  @media (min-width: 576px) {
    margin-right: 7.36%;
  }
`

const AwardsRowDescMoreWrapper = styled(AwardsRow)`
  padding-bottom: 60px;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  @media (max-width: 991px) {
    padding-bottom: 20px;
  }
`

const AwardsColDescMoreImage = styled(AwardsCol)`
  flex: 0 0 55%;
  max-width: 55%;
  padding-left: 30px;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 4%;
  }
  @media (max-width: 991px) {
    ${PaddingsContainerMixin}
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const AwardsHeaderComplectsQtyWrapper = styled.div`
  @media (max-width: 991px) {
    text-align: center;
  }
`

const AwardsHeaderComplectsQty = styled(PriceHeaderQty)`
  position: relative;
  ${(props) =>
    props.type === 'main' &&
    `
    left: -22.5%;
    font-size: 220px;
  `}
  ${(props) =>
    props.type === 'additional' &&
    `
    right: -7.5%;
  `}
  @media (min-width: 1600px) {
    ${(props) =>
      props.type === 'main' &&
      `
      font-size: 260px; 
    `}
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    ${(props) =>
      props.type === 'main' &&
      `
      font-size: 240px; 
    `}
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    ${(props) =>
      props.type === 'main' &&
      `
      font-size: 210px;
      left: -23%;
    `}
  }
  @media (max-width: 991px) {
    position: static;
    margin-bottom: 30px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    ${(props) =>
      props.type === 'main' &&
      `
      font-size: 145px;
    `}
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${(props) =>
      props.type === 'main' &&
      `
      font-size: 120px;
    `}
  }
  @media (max-width: 575px) {
    ${(props) =>
      props.type === 'main' &&
      `
      font-size: 100px;
    `}
  }
  .qtyText {
    ${(props) =>
      props.type === 'main' &&
      `
      font-size: 38px;
      right: -70px;
      bottom: 110px;
    `}
    @media (min-width: 1600px) {
      ${(props) =>
        props.type === 'main' &&
        `
        font-size: 36px;
        letter-spacing: 8px;
        right: -90px;
        bottom: 142px;
      `}
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      ${(props) =>
        props.type === 'main' &&
        `
        font-size: 34px;
        letter-spacing: 6px;
        right: -80px;
        bottom: 126px;
      `}
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      ${(props) =>
        props.type === 'main' &&
        `
        font-size: 30px;
        letter-spacing: 5px;
        right: -80px;
        bottom: 111px;
      `}
    }
    @media (min-width: 768px) and (max-width: 991px) {
      ${(props) =>
        props.type === 'main' &&
        `
        font-size: 24px;
      `}
    }
    @media (min-width: 576px) and (max-width: 767px) {
      ${(props) =>
        props.type === 'main' &&
        `
        font-size: 24px;
        margin-top: -15px;
      `}
    }
    @media (max-width: 575px) {
      ${(props) =>
        props.type === 'main' &&
        `
        font-size: 22px;
        margin-top: -14px;
      `}
    }
  }
`

const Awards = () => {
  const data = useStaticQuery(graphql`
    query {
      awardsImage: file(relativePath: { eq: "awards.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      awardsMoreAwardsImage: file(relativePath: { eq: "awards-more.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const mediaSizesAwardsCircle = [
    {
      '1600': {
        circleSize: 340,
        insetSize: 270,
      },
    },
    {
      '1200': {
        circleSize: 310,
        insetSize: 250,
      },
    },
    {
      '992': {
        circleSize: 280,
        insetSize: 220,
      },
    },
    {
      '768': {
        circleSize: 180,
        insetSize: 140,
      },
    },
    {
      '576': {
        circleSize: 160,
        insetSize: 120,
      },
    },
    {
      '0': {
        circleSize: 80,
        insetSize: 56,
      },
    },
  ]

  const [barAnimate, setBarAnimate] = useState(false)
  const [percentCircleAnimate, setPercentCircleAnimate] = useState(0)

  const setBarAnimateAction = (isVisible) => {
    if (isVisible) {
      setBarAnimate(true)
    }
  }

  const setPercentCircleAnimateAction = (isVisible) => {
    if (isVisible) {
      setPercentCircleAnimate(100)
    }
  }

  return (
    <AwardsWrapper id="awards">
      <AwardsContainer>
        <AwardsRow>
          <AwardsColHeader>
            <AwardsHeaderTitle>Nagrody</AwardsHeaderTitle>
          </AwardsColHeader>
        </AwardsRow>
        <AwardsRow>
          <AwardsColDesc>
            <AwardsDescWrapper>
              <AwardsCircleAnimate
                mediaSizes={mediaSizesAwardsCircle}
                circleBackground="transparent"
                circleColor="#008132"
                insetColor="#e8e8e8"
                transitionLength={1.5}
                percentCircleAnimate={percentCircleAnimate}
              />
              <AwardsDescInnerWrapper>
                <VisibilitySensor onChange={setPercentCircleAnimateAction}>
                  <h3>Nagroda główna</h3>
                </VisibilitySensor>
                <p>
                  Przybij piątkę z{'\u00A0'}Tomkiem Kucharem podczas
                  całodziennego treningu jazdy na{'\u00A0'}torze "Driveland" w
                  {'\u00A0'}
                  Słabomierzu!
                </p>
                <p>
                  Sprawdź się na{'\u00A0'}szarpaku, kole poślizgowym, uniknij
                  czołówki z{'\u00A0'}kurtynami wodnymi, albo przeżyj dachowanie
                  w symulatorze!
                </p>
              </AwardsDescInnerWrapper>
              <AwardsHeaderComplectsQtyWrapper>
                <AwardsHeaderComplectsQty type="main">
                  <div className="qtyWrapper">
                    <span>60</span> <span className="qtyText">nagród</span>
                  </div>
                </AwardsHeaderComplectsQty>
              </AwardsHeaderComplectsQtyWrapper>
            </AwardsDescWrapper>
          </AwardsColDesc>
          <AwardsColDescImage animate={barAnimate}>
            <VisibilitySensor onChange={setBarAnimateAction}>
              <div className="barAnimate" />
            </VisibilitySensor>
            <Img fluid={data.awardsImage.childImageSharp.fluid} />
          </AwardsColDescImage>
        </AwardsRow>
        <AwardsRowDescMoreWrapper>
          <AwardsColDescMoreImage>
            <Img fluid={data.awardsMoreAwardsImage.childImageSharp.fluid} />
          </AwardsColDescMoreImage>
          <AwardsColDescMore>
            <AwardsDescMoreWrapper>
              <AwardsHeaderComplectsQtyWrapper>
                <AwardsHeaderComplectsQty type="additional">
                  <div className="qtyWrapper">
                    <span>100</span> <span className="qtyText">zestawów</span>
                  </div>
                </AwardsHeaderComplectsQty>
              </AwardsHeaderComplectsQtyWrapper>
              <h3>Dodatkowe nagrody!</h3>
              <p>
                <strong>
                  100 wyjątkowych zestawów Schaeffler limited collection
                </strong>
                , które dodadzą unikatowego wyglądu Twojemu warsztatowi.
                <br /> Pokaż wszystkim jak wygląda warsztat, w krórym montujecie
                oryginalne części zawieszenia FAG!
              </p>
            </AwardsDescMoreWrapper>
          </AwardsColDescMore>
        </AwardsRowDescMoreWrapper>
      </AwardsContainer>
    </AwardsWrapper>
  )
}

export default Awards
