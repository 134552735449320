import React from 'react'
import styled from 'styled-components'

import Navbar from './navbar'
import Intro from './intro'

import medalImage from '../images/medal-min.png'

const HeaderWrapper = styled.div`
  position: relative;
  border-bottom: 15px solid ${(props) => props.theme.colorWhite};
  @media (min-width: 992px) {
    padding-top: 50px;
  }
  @media (max-width: 991px) {
    overflow: hidden;
  }
`

const HeaderBG = styled.div`
  position: absolute !important;
  top: 0;
  right: 15.1%;
  bottom: 0;
  width: 1200px;
  height: calc(100% + 120px);
  background-image: url(${medalImage});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain !important;
  z-index: 10;
  @media (min-width: 1200px) and (max-width: 1599px) {
    right: 14%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 1000px;
    right: 12%;
  }
  @media (max-width: 991px) {
    width: auto;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 40%;
  }
`

const Header = () => {
  return (
    <HeaderWrapper>
      <HeaderBG />
      <Navbar />
      <Intro />
    </HeaderWrapper>
  )
}

export default Header
