import React from 'react'
import styled, { css } from 'styled-components'

export const ContainerMixin = css/* styled */ `
  width: 100%;
  max-width: 1920px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`

export const RowMixin = css/* styled */ `
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`

export const ColMixin = css/* styled */ `
  flex: 0 0 100%;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
`

export const PaddingsContainerMixin = css/* styled */ `
  padding-left: 7.885%;
  padding-right: 7.885%;
`

export const HeaderContainerMixin = css/* styled */ `
  ${ContainerMixin}
  padding-left: 7.89%;
  padding-right: 5.26%;
`

export const DescMixin = css/* styled */ `
  font-family: ${(props) => props.theme.fontMetaPro};
  line-height: 1.4;
  h3 {
    font-size: 34px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 70px;
    @media (min-width: 1200px) and (max-width: 1599px) {
      font-size: 32px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 30px;
      margin-bottom: 50px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 28px;
      margin-bottom: 45px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 26px;
      margin-bottom: 40px;
    }
    @media (max-width: 575px) {
      font-size: 22px;
      margin-bottom: 25px;
    }
  }
  p {
    font-size: 32px;
    margin-bottom: 60px;
    @media (min-width: 1200px) and (max-width: 1599px) {
      font-size: 26px;
      margin-bottom: 40px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 24px;
      margin-bottom: 35px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 22px;
      margin-bottom: 35px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 20px;
      margin-bottom: 25px;
    }
    @media (max-width: 575px) {
      font-size: 18px;
      margin-bottom: 20px;
    }
    &.numberRule {
      // font-family: ${(props) => props.theme.fontMetaPro};
      font-family: ff-meta-correspondence-web-p,sans-serif;
      color: ${(props) => props.theme.colorGreen};
      font-size: 98px;
      font-weight: 700;
      font-style: normal;
      line-height: 0.85;
      margin-top: 15px;
      margin-bottom: 5px;
      @media (min-width: 1200px) and (max-width: 1599px) {
        font-size: 80px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 75px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        margin-bottom: -80px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 70px;
        margin-bottom: -65px;
      }
      @media (max-width: 575px) {
        font-size: 70px;
        margin-bottom: -65px;
      }
    }
    &.withNumberRule {
      @media (min-width: 768px) and (max-width: 991px) {
        padding-left: 80px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        padding-left: 60px;
      }
      @media (max-width: 575px) {
        padding-left: 60px;
      }
    }
    &.titleDesc {
      font-family: ${(props) => props.theme.fontMetaPro};
      font-size: 28px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 15px;
      margin-bottom: -10px;
      @media (min-width: 1200px) and (max-width: 1599px) {
        font-size: 27px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 25px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
      }
      @media (max-width: 767px) {
        line-height: 1.2;
        margin-top: 5px;
        margin-bottom: -3px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 25px;
      }
      @media (max-width: 575px) {
        font-size: 22px;
      }
    }
    &.no-mb {
      margin-bottom: 0;
    }
  }
  strong {
    font-family: ${(props) => props.theme.fontMetaPro};
    font-weight: 700;
  }
  .green {
    color: ${(props) => props.theme.colorGreen};
  }
`

export const TitleMixin = css/* styled */ `
  font-family: ${(props) => props.theme.fontMetaPro};
  font-weight: 700;
  text-transform: uppercase;
  span {
    display: block;
    &.firstText {
      font-size: 70px;
      @media (min-width: 1200px) and (max-width: 1599px) {
        font-size: 58px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 40px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 44px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 40px;
      }
      @media (max-width: 575px) {
        font-size: 30px;
      }
    }
    &.lastText {
      font-size: 29px;
      @media (min-width: 1200px) and (max-width: 1599px) {
        font-size: 25px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 18px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 20px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 18px;
      }
      @media (max-width: 575px) {
        font-size: 14px;
      }
    }
  }
`

export const Button = styled.a`
  display: inline-block;
  background-color: ${(props) => props.bgColor && props.bgColor};
  color: ${(props) => props.color && props.color};
  font-family: ${(props) => props.theme.fontMetaPro};
  font-size: 30px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 50px;
  transition: 0.2s ease;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 26px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 26px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 24px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @media (max-width: 575px) {
    font-size: 22px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &:hover {
    background-color: ${(props) => props.bgColorHover && props.bgColorHover};
    color: ${(props) => props.colorHover && props.colorHover};
    opacity: 0.9;
  }
`

export const PriceHeaderQty = styled.div`
  position: relative;
  display: inline-block;
  color: #8f8f8f;
  font-family: 'ff-meta-correspondence-web-p', sans-serif;
  font-size: 165px;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1;
  padding-right: 30px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 126px;
    padding-right: 20px;
  }
  @media (max-width: 991px) {
    padding-right: 0;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 110px;
    padding-top: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 100px;
    padding-top: 20px;
  }
  @media (max-width: 575px) {
    font-size: 80px;
    padding-top: 15px;
  }
  span {
    display: inline-block;
    &:first-of-type {
      vertical-align: text-top;
    }
  }
  .qtyWrapper {
    display: inline-block;
  }
  .qtyText {
    right: -45px;
    bottom: 84px;
    font-family: ${(props) => props.theme.fontMetaPro};
    font-size: 22px;
    @media (min-width: 992px) {
      position: absolute;
      transform: rotate(-90deg);
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      right: -40px;
      bottom: 63px;
      font-size: 17px;
    }
    @media (max-width: 991px) {
      display: block;
      text-align: center;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 20px;
      margin-top: -15px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 18px;
      margin-top: -12px;
    }
    @media (max-width: 575px) {
      font-size: 16px;
      margin-top: -10px;
    }
  }
`

const circleAnimateTemplate = (
  circleSize,
  circleBackground,
  circleColor,
  insetSize,
  insetColor,
  transitionLength
) => {
  return `
  .ko-progress-circle {
    margin: auto;
    width:  ${circleSize}px;
    height: ${circleSize}px;

    background-color: ${circleBackground};
    border-radius: 50%;
      .ko-progress-circle__slice, .ko-progress-circle__fill {
        width:    ${circleSize}px;
        height:   ${circleSize}px;
        position: absolute;
        -webkit-backface-visibility: hidden;
        transition: transform ${transitionLength}s;
        border-radius: 50%;
      }
      .ko-progress-circle__slice {
        clip: rect(0px, ${circleSize}px, ${circleSize}px, ${circleSize / 2}px);
        .ko-progress-circle__fill {
          clip: rect(0px, ${circleSize / 2}px, ${circleSize}px, 0px);
          background-color: ${circleColor};
        }
    }
    .ko-progress-circle__overlay {
      width:       ${insetSize}px;
      height:      ${insetSize}px;
      position:    absolute;
      margin-left: ${(circleSize - insetSize) / 2}px;
      margin-top:  ${(circleSize - insetSize) / 2}px;

      background-color: ${insetColor};
      border-radius: 50%;
    }



  }
  `
}

const circleAnimateFunc = () => {
  let i = 0
  const increment = 180 / 100
  let scssStyle = ''

  while (i <= 100) {
    scssStyle =
      scssStyle +
      `
    &[data-progress='${i}'] {
      .ko-progress-circle__slice.full, .ko-progress-circle__fill {
        transform: rotate(${increment * i}deg);
      }
      .ko-progress-circle__fill.ko-progress-circle__bar {
        transform: rotate(${increment * i * 2}deg);
      }
    }

    `
    i++
  }

  return scssStyle
}

const circleAnimateMediaSizes = (
  mediaSizes,
  circleBackground = '#cfcfcf',
  circleColor = '#008132',
  insetColor = '#e8e8e8',
  transitionLength = 1.5
) => {
  let mediaQueries = ''

  mediaSizes.forEach((mediaSizeObj, idx) => {
    const sizes = Object.keys(mediaSizeObj)
    const size = sizes[0]

    const circleSize = mediaSizeObj[size].circleSize
    const insetSize = mediaSizeObj[size].insetSize

    if (idx === 0) {
      mediaQueries =
        mediaQueries +
        `
        @media (min-width: ${parseInt(size)}px) {
          ${circleAnimateTemplate(
            circleSize,
            circleBackground,
            circleColor,
            insetSize,
            insetColor,
            transitionLength
          )}
        }
      `
    }

    if (idx !== 0 && idx !== mediaSizes.length - 1) {
      const prevSize = Object.keys(mediaSizes[idx - 1])

      mediaQueries =
        mediaQueries +
        `
        @media (min-width: ${parseInt(size)}px) and (max-width: ${
          parseInt(prevSize[0]) - 1
        }px) {
          ${circleAnimateTemplate(
            circleSize,
            circleBackground,
            circleColor,
            insetSize,
            insetColor,
            transitionLength
          )}
        }
      `
    }

    if (idx === mediaSizes.length - 1) {
      const prevSize = Object.keys(mediaSizes[idx - 1])

      mediaQueries =
        mediaQueries +
        `
        @media (max-width: ${parseInt(prevSize[0]) - 1}px) {
          ${circleAnimateTemplate(
            circleSize,
            circleBackground,
            circleColor,
            insetSize,
            insetColor,
            transitionLength
          )}
        }
      `
    }
  })

  return mediaQueries
}

export const CircleAnimateWrapper = styled.div`
  display: block;
  ${(props) =>
    circleAnimateMediaSizes(
      props.mediaSizes,
      props.circleBackground,
      props.circleColor,
      props.insetColor,
      props.transitionLength
    )}

  .ko-progress-circle {
    ${circleAnimateFunc()}
  }
`

export const CircleAnimate = (props) => {
  return (
    <div className={props.className}>
      <CircleAnimateWrapper
        mediaSizes={props.mediaSizes}
        circleBackground={props.circleBackground}
        circleColor={props.circleColor}
        insetColor={props.insetColor}
        transitionLength={props.transitionLength}
      >
        <div
          className="ko-progress-circle"
          data-progress={props.percentCircleAnimate}
        >
          <div className="ko-circle">
            <div className="full ko-progress-circle__slice">
              <div className="ko-progress-circle__fill"></div>
            </div>
            <div className="ko-progress-circle__slice">
              <div className="ko-progress-circle__fill"></div>
              <div className="ko-progress-circle__fill ko-progress-circle__bar"></div>
            </div>
          </div>
          <div className="ko-progress-circle__overlay"></div>
        </div>
      </CircleAnimateWrapper>
    </div>
  )
}
